import * as React from "react";
import { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ArrowExternal from "../../images/arrow-external.png";
import $ from "jquery";

class Content extends Component {
  componentDidMount() {
    var hash = window.location.hash;
    if (hash !== "#join-our-programs") return;
    var position = $(hash).offset().top;
    setTimeout(function () {
      let isMobile = $(window).width() <= 767;
      let offset = isMobile ? 110 : 35;
      let scrollValue = position - offset;
      window.scrollTo({
        top: scrollValue,
        behavior: "smooth",
      });
    }, 100);
  }
  render() {
    return (
      <div className={`${this.props.show ? "d-block" : "d-none"}`}>
        <Container
          fluid
          className="hero events"
          role="img"
          aria-label="Group of people standing
                      outside in a city"
        />
        <Container className="care-continuum">
          <Row>
            <Col xs={9} lg={10} className="m-auto px-0">
              <div className="hero-callout muted-yellow-dark">
                <h1 className="sub-title ">Events</h1>
                <h1 className="title">
                  Working side by side with the community
                </h1>
              </div>
              <Container>
                <Row>
                  <Col xs={12} className="px-0">
                    <p className="pb-3">
                      For more than 30 years, Gilead has worked with the
                      community toward our collective, common goal of helping
                      end the HIV epidemic. Through working alongside the
                      community, Gilead is supporting the innovative work of
                      grassroots organizations to help improve access to care by
                      eliminating barriers and advancing education.
                    </p>
                    <h2 id="join-our-programs" className="title">
                      Join Our Programs & Events IN THE COMMUNITY
                    </h2>
                  </Col>
                  <Container>
                    {/* this container contents the cards */}
       
                    <Row className="event-cards">
                     
                      <Col lg={4} className="event-card the-naesm-national">
                       
                        <span className="date">6/22/2024</span>
                        <span className="title">
                        Press Play on Knowing Your HIV Status: Orlando, FL 
                        </span>
                        <span className="description">
                           Gilead is proud to sponsor this National HIV Testing Day event. Free HIV testing will be offered by local community organizations as well as live entertainment, giveaways, and vendors.
                           <span className="d-block py-3">
                              Consulado de México<br/>
                              2550 Technology Drive<br/>
                              Orlando, FL 32804<br/>
                           </span>
                           June 22, 10 <small>AM</small> - 2:30 <small>PM</small> ET
                        </span>
                        
                        
                     </Col>

                     <Col lg={4} className="event-card the-naesm-national">
                        
                        <span className="date">6/22/2024</span>
                        <span className="title">
                        Press Play on Knowing Your HIV Status: Fort Lauderdale, FL 
                        </span>
                        <span className="description">
                        Gilead is proud to sponsor this National HIV Testing Day event. Free HIV testing will be offered by local community organizations as well as live entertainment, giveaways, and vendors. 
                           <span className="d-block pt-3">
                              Old Dillard Museum
                           </span>
                           <span className="d-block">
                              1009 NW 4th Street 
                           </span>
                           <span className="d-block pb-3">
                              Fort Lauderdale, FL 33311 
                           </span>
                           June 22, 10 <small>AM</small> - 3 <small>PM</small> ET
                        </span>
      
                     </Col>
                     
                     <Col lg={4} className="event-card the-naesm-national">
                       
                        <span className="date">6/25/2024</span>
                        <span className="title">
                        Press Play on Knowing Your HIV Status: Dallas, TX  
                        </span>
                        <span className="description">
                        Gilead is proud to sponsor this National HIV Testing Day event. Free HIV testing will be offered by local community organizations as well as live entertainment, giveaways, and vendors. 
                        <span className="d-block py-3">
                              Resource Center<br />
                              5750 Cedar Springs Road<br />
                              Dallas, TX 75235
                           </span>
                           June 25, 4 <small>PM</small> - 8 <small>PM</small> CT
                        </span>
                     </Col>

                     
                    </Row>
                    <Row className="event-cards">
                     
                      <Col lg={4} className="event-card the-naesm-national">
                        
                        <span className="date">6/25/2024</span>
                        <span className="title">
                        Press Play on Knowing Your HIV Status: New York City, NY  
                        </span>
                        <span className="description">
                        Gilead is proud to sponsor this National HIV Testing Day event. Free HIV testing will be offered by local community organizations as well as live entertainment, giveaways, and vendors.
                           <span className="d-block py-3">
                           Union Square Park
                           </span>
                           June 25, 3 <small>PM</small> - 6 <small>PM</small> ET
                        </span>

                     </Col>

                     

                     <Col lg={4} className="event-card the-naesm-national">
                        
                        <span className="date">6/27/2024</span>
                        <span className="title">
                        Press Play on Knowing Your HIV Status: Memphis, TN
                        </span>
                        <span className="description">
                        Gilead is proud to sponsor this National HIV Testing Day event. Free HIV testing will be offered by local community organizations as well as live entertainment, giveaways, and vendors.
                           <span className="d-block pt-3">
                              Shelby County Department of Health Headquarters
                           </span>
                           <span className="d-block">
                              814 Jefferson Ave.
                           </span>
                           <span className="d-block pb-3">
                              Memphis, TN 38105
                           </span>
                           June 27, 10 <small>AM</small> - 2 <small>PM</small> CT
                        </span>
         
                     </Col>
                     <Col lg={4} className="event-card the-naesm-national">
                        
                        <span className="date">6/29/2024</span>
                        <span className="title">
                        Press Play on Knowing Your HIV Status: Atlanta, GA 
                        </span>
                        <span className="description">
                        Gilead is proud to sponsor this National HIV Testing Day event. Free HIV testing will be offered by local community organizations as well as live entertainment, giveaways, and vendors.
                           <span className="d-block py-3">
                              2672 Godby Road<br />
                              College Park, GA 30349
                           </span>
                           June 29, 12 <small>PM</small> - 8 <small>PM</small> ET
                        </span>
                     
                     </Col>
                    </Row>
                    <Row className="event-cards">
                        <Col lg={4} className="event-card the-naesm-national">
                           
                           <span className="date">6/29/2024</span>
                           <span className="title">
                           Press Play on Knowing Your HIV Status: Los Angeles, CA
                           </span>
                           <span className="description">
                           Gilead is proud to sponsor this National HIV Testing Day event. Free HIV testing will be offered by local community organizations as well as live entertainment, giveaways, and vendors. 
                              <span className="d-block py-3">
                                 Club Chico<br />
                                 2915 W. Beverly Blvd<br />
                                 Montebello, CA 90640
                              </span>
                              June 29, 6 <small>PM</small> - 10 <small>PM</small> PT
                           </span>

                        </Col>

                        <Col lg={4} className="event-card the-naesm-national">
                           <a
                              href="https://www.nbna.org/conf"
                              target="_blank"
                           >
                           <span className="date">7/23/2024</span>
                           <span className="title">
                           The 52nd Annual National Black Nurses Association, Inc (NBNA) Institute & Conference
                           </span>
                           <span className="description">
                           Join us at the 2024 NBNA Conference designed to equip attendees with the requisite knowledge needed to address <span className="no-break">cross-cutting</span> issues affecting nursing, health care, and health equity. This year's theme is "Advancing Health Care Across the Lifespan: Through <span className="no-break">Re-Imagining</span> Nursing and Human Caring."
                           </span>
                           <span className="register">
                           Learn More <img src={ArrowExternal} />
                           </span>
                           </a>
                        </Col>

                        <Col lg={4} className="event-card the-naesm-national">
                           <a
                              href="https://convention.nmanet.org/"
                              target="_blank"
                           >
                           <span className="date">8/3/2024</span>
                           <span className="title">
                           National Medical Association (NMA) 2024 Annual Convention & Scientific Assembly
                           </span>
                           <span className="description">
                           Join more than 2,500 leading African American physicians, physician executives, health professionals, academicians, and scientists in over 26 specialties from across the country at the NMA 2024 Annual Convention & Scientific Assembly.
                           </span>
                           <span className="register">
                           Learn More <img src={ArrowExternal} />
                           </span>
                           </a>
                        </Col>

                        
                    </Row>

                    <Row className="event-cards">
                        <Col lg={4} className="event-card the-naesm-national">
                           <a
                              href="https://uscha.life/register/"
                              target="_blank"
                           >
                           <span className="date">9/12/2024</span>
                           <span className="title">
                           2024 USCHA
                           </span>
                           <span className="description">
                           Join us at the 2024 US Conference on HIV/AIDS (USCHA), the premier HIV conference and largest <span className="no-break">HIV-related</span> gathering in the United States.
                           </span>
                           <span className="register">
                           Register Now <img src={ArrowExternal} />
                           </span>
                           </a>
                        </Col>

                        <Col lg={4} className="event-card the-naesm-national">
                           <a
                              href="https://www.nursesinaidscare.org/i4a/pages/index.cfm?pageID=4623"
                              target="_blank"
                           >
                           <span className="date">11/14/2024</span>
                           <span className="title">
                           ANAC2024 Conference
                           </span>
                           <span className="description">
                           We're excited to join nurses and AHCPs who work in HIV care at the Association of Nurses in AIDS Care (ANAC) Conference.
                           </span>
                           <span className="register">
                           Learn More <img src={ArrowExternal} />
                           </span>
                           </a>
                        </Col>

                        <Col lg={4} className="event-card">
                           
                           &nbsp;

                        </Col>
                    </Row>
                  </Container>
                  <Col xs={12} className="px-0 mt-4 pt-4 mb-4 pb-3">
                    <h2 className="title">AIDS WALKS SPONSORSHIPS</h2>
                    <p>
                      For decades, community activists have organized AIDS
                      Walks, inviting people from all over the world and all
                      walks of life to join in solidarity. AIDS Walks not only
                      honor and remember those who have lost their lives to
                      AIDS, but also raise awareness and gather resources needed
                      to continue progress and help save lives. Supporting AIDS
                      Walk events is a necessary part of our role in helping end
                      the HIV epidemic, as these events bring the community
                      together, on the ground, renewing commitment to our
                      collective mission. Connect with us on{" "}
                      <a
                        class="social-link"
                        href="https://www.instagram.com/gileadhivus/"
                        target="_blank"
                        rel="nofollow noopener"
                      >
                        Instagram
                      </a>{" "}
                      and{" "}
                      <a
                        class="social-link"
                        href="https://www.facebook.com/GileadHIVUS/"
                        target="_blank"
                        rel="nofollow noopener"
                      >
                        Facebook
                      </a>{" "}
                      for updates on AIDS Walks.
                    </p>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Content;
